import { marked } from "marked"
import { Container } from "../components/container"
import { Heading } from "../components/heading"

type CoverageItem = {
  icon: string
  heading: string
  description: string
}

type HomepageCoverageProps = {
  blok: {
    title: string
    items: CoverageItem[]
  }
}

export const HomepageCoverage = (props: HomepageCoverageProps) => {
  return (
    <Container>
      <article className="bg-neutral-100 p-10 space-y-8 xl: py-16 lg:mt-24">
        <div className="text-center mb-6">
          <Heading variant="h2" component={"h3"}>
            {props.blok.title}
          </Heading>
        </div>
        <div className="grid md:grid-cols-2 gap-4 lg:gap-x-16">
          {props.blok.items.map((item, index) => {
            return (
              <section
                key={index}
                className="mb-8 text-neutral-800 text-lg lg:text-xl space-y-3"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: marked(item.icon) }}
                ></div>
                <h4 className="font-montserrat font-bold">{item.heading}</h4>
                <div
                  className="font-roboto"
                  dangerouslySetInnerHTML={{ __html: marked(item.description) }}
                />
              </section>
            )
          })}
        </div>
      </article>
    </Container>
  )
}
