import { HomeHero } from "../sections/hero"
import { Stats } from "../sections/stats"
import { FeaturedIn } from "../sections/featured-in"
import { WhatWeDo } from "../sections/what-we-do"
import { HomepageCoverage } from "../sections/homepage-coverage"
import { HomepageReviews } from "../sections/homepage-reviews"
import { HomepageShowcase } from "../sections/homepage-showcase"
import { HomepageOffer } from "../sections/homepage-offer"
import { HomepageSupport } from "../sections/homepage-support"
import { HomepageMeetDriver } from "../sections/homepage-meet-drivers"
import { HomepageCTA } from "../sections/homepage-cta"
import Page from "../../storyblok/containers/Page"
import HomepageLinkBlock from "../../components/link-block"
import HomepageOfferCards from "../../components/cards/OfferCard"

export const components = {
  HomeHero,
  Stats,
  FeaturedIn,
  WhatWeDo,
  HomepageCoverage,
  HomepageReviews,
  HomepageShowcase,
  HomepageOffer,
  HomepageSupport,
  HomepageMeetDriver,
  HomepageCTA,
  page: Page,
  HomepageLinkBlock,
  HomepageOfferCards,
}
