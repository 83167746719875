import { IIconProps } from "./index"

export default function TrustpilotRatingIcon(props: IIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="24" {...props}>
      <path
        fill="#00B67A"
        d="M24 0H0v24h24V0ZM50 0H26v24h24V0ZM76 0H52v24h24V0ZM102 0H78v24h24V0ZM128 0h-24v24h24V0Z"
      />
      <path
        fill="#fff"
        d="m12 16.175 3.65-.925 1.525 4.7L12 16.175Zm8.4-6.075h-6.425L12 4.05l-1.975 6.05H3.6l5.2 3.75-1.975 6.05 5.2-3.75 3.2-2.3L20.4 10.1ZM38 16.175l3.65-.925 1.525 4.7L38 16.175Zm8.4-6.075h-6.425L38 4.05l-1.975 6.05H29.6l5.2 3.75-1.975 6.05 5.2-3.75 3.2-2.3L46.4 10.1ZM64 16.175l3.65-.925 1.525 4.7L64 16.175Zm8.4-6.075h-6.425L64 4.05l-1.975 6.05H55.6l5.2 3.75-1.975 6.05 5.2-3.75 3.2-2.3L72.4 10.1ZM90 16.175l3.65-.925 1.525 4.7L90 16.175Zm8.4-6.075h-6.425L90 4.05l-1.975 6.05H81.6l5.2 3.75-1.975 6.05 5.2-3.75 3.2-2.3L98.4 10.1ZM116 16.175l3.65-.925 1.525 4.7L116 16.175Zm8.4-6.075h-6.425L116 4.05l-1.975 6.05H107.6l5.2 3.75-1.975 6.05 5.2-3.75 3.2-2.3 5.175-3.75Z"
      />
    </svg>
  )
}
