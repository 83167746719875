import * as React from "react"
import Link from "next/link"
import type { LinkProps } from "next/link"

// Combine LinkProps with AnchorProps to support all Next.js Link props
type AnchorProps = Omit<React.ComponentPropsWithoutRef<"a">, "href"> &
  LinkProps & {
    href: string
    newTab?: boolean
    dofollow?: boolean
    sponsored?: boolean
  }

/**
 * Enhanced Anchor component that handles both internal and external links.
 *
 * Features:
 * - Uses Next.js Link for internal navigation
 * - Handles external links with appropriate rel attributes
 * - Supports all Next.js Link props
 * - Respects newTab prop for all links
 * - External links open in new tab by default
 * - Special handling for CitizenShipper links to preserve analytics tracking and SEO
 */
export const Anchor = React.memo(
  ({
    href,
    children,
    newTab = false,
    className,
    dofollow = false,
    sponsored = false,
    prefetch = false, // Default to false to avoid unnecessary prefetching
    replace,
    scroll,
    shallow,
    locale,
    passHref,
    ...props
  }: AnchorProps) => {
    // Validate and normalize the href
    const normalizedHref = React.useMemo(() => {
      try {
        // Handle empty or invalid hrefs
        if (!href || typeof href !== "string") {
          console.warn("Invalid href provided to Anchor component:", href)
          return "#"
        }
        return href
      } catch (error) {
        console.warn("Error processing href in Anchor component:", error)
        return "#"
      }
    }, [href])

    // Determine if the link is external
    const isExternal = React.useMemo(
      () =>
        normalizedHref.startsWith("http") ||
        normalizedHref.startsWith("https") ||
        normalizedHref.startsWith("//") ||
        normalizedHref.startsWith("mailto:") ||
        normalizedHref.startsWith("tel:"),
      [normalizedHref],
    )

    // Check if it's a CitizenShipper link by properly extracting and checking only the hostname
    const isCitizenShipper = React.useMemo(() => {
      try {
        if (!isExternal) return false

        // For protocol-relative URLs, prepend https: to make URL parsing work
        const urlToCheck = normalizedHref.startsWith("//")
          ? `https:${normalizedHref}`
          : normalizedHref

        // Skip non-HTTP/HTTPS URLs (mailto:, tel:, etc.)
        if (!urlToCheck.startsWith("http")) return false

        // Extract hostname using URL API
        const url = new URL(urlToCheck)
        const hostname = url.hostname

        // Check if the hostname ends with citizenshipper.com or is exactly citizenshipper.com
        // Also include help.citizenshipper.com and other subdomains
        return (
          hostname === "citizenshipper.com" ||
          hostname.endsWith(".citizenshipper.com")
        )
      } catch (error) {
        // If URL parsing fails, fall back to the simple check
        console.warn("Error parsing URL in Anchor component:", error)
        return normalizedHref.includes("citizenshipper.com")
      }
    }, [normalizedHref, isExternal])

    // Respect newTab prop for all links
    // For external links, open in new tab by default unless newTab is explicitly set to false
    const shouldOpenNewTab = newTab !== false && isExternal ? true : newTab

    // Set target and rel attributes
    let target: "_blank" | "_self" = shouldOpenNewTab ? "_blank" : "_self"
    let rel: string | undefined = undefined

    // Handle rel attribute logic in a cleaner way
    if (isExternal) {
      if (isCitizenShipper) {
        // CitizenShipper links: only add noopener for security when opening in new tab
        rel = shouldOpenNewTab ? "noopener" : undefined
      } else {
        // Other external links
        const relParts = ["external"]

        // Add security attributes for new tab links
        if (shouldOpenNewTab) {
          relParts.push("noopener", "noreferrer")
        }

        // Add SEO attributes
        if (!dofollow) relParts.push("nofollow")
        if (sponsored) relParts.push("sponsored")

        rel = relParts.join(" ")
      }
    } else if (shouldOpenNewTab) {
      // Internal links in new tab need security attributes
      rel = "noreferrer noopener"
    }

    // Manually fix the BBB link issue
    if (normalizedHref.includes("bbb.org") && target === "_blank") {
      rel = "noopener noreferrer external"
    }

    // For internal links that should open in a new tab, use regular anchor tag
    if (!isExternal && shouldOpenNewTab) {
      return (
        <a
          {...props}
          href={normalizedHref}
          target={target}
          rel={rel}
          className={className ?? "text-primary-500 hover:underline"}
        >
          {children}
        </a>
      )
    }

    // Use Next.js Link component for internal links that should open in the same tab
    if (!isExternal && !shouldOpenNewTab) {
      return (
        <Link
          href={normalizedHref}
          className={className ?? "text-primary-500 hover:underline"}
          prefetch={prefetch}
          replace={replace}
          scroll={scroll}
          shallow={shallow}
          locale={locale}
          passHref={passHref}
          {...props}
        >
          {children}
        </Link>
      )
    }

    // Use regular anchor tag for external links
    return (
      <a
        {...props}
        href={normalizedHref}
        target={target}
        rel={rel}
        className={className ?? "text-primary-500 hover:underline"}
      >
        {children}
      </a>
    )
  },
)

// Add display name for React DevTools and error messages
Anchor.displayName = "Anchor"
