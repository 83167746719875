"use client"
import { storyblokInit, apiPlugin } from "@storyblok/react"
import * as React from "react"

import { components } from "../storyblok/index"
import { env } from "../../config"

/** 2. Initialize it as usual */
storyblokInit({
  accessToken: env("STORYBLOK_API_KEY"),
  use: [apiPlugin],
  apiOptions: {
    region: "us",
  },
  components,
})

export function StoryblokProvider({ children }: { children: React.ReactNode }) {
  return <>{children}</>
}
