export const categories = [
  {
    label: "Pets & Animals",
    link: "/shipments/new/animals",
    subcategories: [
      {
        label: "Dogs",
        link: "/shipments/new/animals/dogs",
      },
      {
        label: "Cats",
        link: "/shipments/new/animals/cats",
      },
      {
        label: "Horses",
        link: "/shipments/new/animals/horses",
      },
      {
        label: "Other",
        link: "/shipments/new/animals/other",
      },
    ],
  },
  {
    label: "Motorcycles",
    link: "/shipments/new/motorcycles",
    subcategories: [
      {
        label: "Racing Bikes",
        link: "/shipments/new/motorcycles/racing-bikes",
      },
      {
        label: "Dirt Bikes",
        link: "/shipments/new/motorcycles/dirt-bikes",
      },
      {
        label: "Scooters",
        link: "/shipments/new/motorcycles/scooters",
      },
      {
        label: "Cruising Bikes / Choppers",
        link: "/shipments/new/motorcycles/choppers",
      },
      {
        label: "ATVs",
        link: "/shipments/new/motorcycles/atvs",
      },
      {
        label: "3-Wheelers",
        link: "/shipments/new/motorcycles/3-wheelers",
      },
      {
        label: "Other Motorcycles",
        link: "/shipments/new/motorcycles/others",
      },
    ],
  },
  {
    label: "Vehicles",
    link: "/shipments/new/vehicles",
    subcategories: [
      {
        label: "Cars and Light Trucks",
        link: "/shipments/new/vehicles/cars",
      },
      {
        label: "RVs",
        link: "/shipments/new/vehicles/rvs",
      },
      {
        label: "Camper Trailers",
        link: "/shipments/new/vehicles/trailers",
      },
      {
        label: "Oversized and Lifted Trucks",
        link: "/shipments/new/vehicles/trucks",
      },
      {
        label: "Classic cars",
        link: "/shipments/new/vehicles/antique-special-care",
      },
      {
        label: "Other Vehicles",
        link: "/shipments/new/vehicles/others",
      },
    ],
  },
  {
    label: "Boats",
    link: "/shipments/new/boats",
    subcategories: [
      {
        label: "Powerboats & Motorboats",
        link: "/shipments/new/boats/power-boats",
      },
      {
        label: "Fishing Boats",
        link: "/shipments/new/boats/fishing-boats",
      },
      {
        label: "Sailboats",
        link: "/shipments/new/boats/sailboats",
      },
      {
        label: "Other Boats",
        link: "/shipments/new/boats/others",
      },
    ],
  },
  {
    label: "Household",
    link: "/shipments/new/household-items",
    subcategories: [
      {
        label: "Furniture",
        link: "/shipments/new/household-items/furniture",
      },
      {
        label: "Kitchen Appliances",
        link: "/shipments/new/household-items/kitchen",
      },
      {
        label: "Boxes",
        link: "/shipments/new/household-items/boxes",
      },
    ],
  },
  {
    label: "Home Moves",
    link: "/shipments/new/apartment-home-moves",
    subcategories: [
      {
        label: "1 Bedroom Apartment",
        link: "/shipments/new/apartment-home-moves/1-bedrooms",
      },
      {
        label: "2 Bedroom Apartment",
        link: "/shipments/new/apartment-home-moves/2-bedrooms",
      },
      {
        label: "3 Bedroom House",
        link: "/shipments/new/apartment-home-moves/3-bedrooms",
      },
      {
        label: "4 Bedroom House",
        link: "/shipments/new/apartment-home-moves/4-bedrooms",
      },
      {
        label: "5 Bedroom House",
        link: "/shipments/new/apartment-home-moves/5-bedrooms",
      },
    ],
  },
  {
    label: "Heavy Equipment",
    link: "/shipments/new/heavy-equipment",
    subcategories: [
      {
        label: "Tractor",
        link: "/shipments/new/heavy-equipment/tractors",
      },
      {
        label: "Excavators",
        link: "/shipments/new/heavy-equipment/excavators",
      },
      {
        label: "Backhoe Loaders",
        link: "/shipments/new/heavy-equipment/backhoe-loaders",
      },
      {
        label: "Skid Steer Loaders",
        link: "/shipments/new/heavy-equipment/skid-steer-loaders",
      },
      {
        label: "Forklifts & Telehandlers",
        link: "/shipments/new/heavy-equipment/forklifts-telehandlers",
      },
      {
        label: "Other Heavy Items",
        link: "/shipments/new/heavy-equipment/others",
      },
    ],
  },
  {
    label: "Freight",
    link: "/shipments/new/freight",
    subcategories: [
      {
        label: "Less than Truckload",
        link: "/shipments/new/freight/less-than-truckload",
      },
      {
        label: "Full Truck Load",
        link: "/shipments/new/freight/full-truckload",
      },
    ],
  },
  {
    label: "All Other Items",
    link: "/shipments/new/others",
    subcategories: [],
  },
]

export const shippingCategories = [
  {
    label: "Pet Transport",
    link: "/pet-transportation",
    subcategories: [],
  },
  {
    label: "Dog Transport",
    link: "/dog-transportation",
    subcategories: [],
  },
  {
    label: "Cat Transport",
    link: "/cat-transportation",
    subcategories: [],
  },
  {
    label: "Motorcycle Shipping",
    link: "/motorcycle-transportation",
    subcategories: [],
  },
  {
    label: "Vehicle Shipping",
    link: "/vehicle-transportation",
    subcategories: [],
  },
  {
    label: "Car Shipping",
    link: "/vehicle-transportation",
    subcategories: [],
  },
  {
    label: "Boat Transport",
    link: "/boat-transportation",
    subcategories: [],
  },
  {
    label: " RV Transport",
    link: "/rv-transport",
    subcategories: [],
  },
  {
    label: "Furniture Shipping",
    link: "/shipping-furniture",
    subcategories: [],
  },
]
