import { DetailedHTMLProps, FC, HTMLAttributes } from "react"
import { SbBlokData } from "@storyblok/react"
import { Anchor } from "../app/components/anchor"

export interface LinkItem
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  header: string
  subtitle?: string
  link: string
}

export interface ILinkBlock extends SbBlokData {
  items?: LinkItem[]
  title?: string
}

export const LinkBlockItem: FC<LinkItem> = (props) => {
  return <div {...props} />
}

const LinkBlock = ({ blok }: { blok: ILinkBlock }) => {
  const { items, title } = blok
  return (
    <section className="mx-auto p-4 md:max-w-[600px] lg:max-w-[1380px]  text-center">
      {title && (
        <h2 className="mx-auto text-md font-montserrat my-8 sm:mb-10">
          {title}
        </h2>
      )}
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-2 mx-auto">
        {items?.map((item, index) => (
          <Anchor
            href={item.link}
            key={index}
            prefetch={false}
            className="block text-gray9 no-underline hover:underline hover:text-blue7"
          >
            <h3 className="text-base font-medium">{item.header}</h3>
            {item.subtitle && (
              <p className="text-sm font-normal">{item.subtitle}</p>
            )}
          </Anchor>
        ))}
      </div>
    </section>
  )
}

export default LinkBlock
