import * as React from "react"
import { Anchor } from "../components/anchor"
import { Link as RedirectLink } from "../components/button"

type HomepageCTAProps = {
  blok: {
    title: string
    description: string
    buttonLabel: string
  }
}

export const HomepageCTA = (props: HomepageCTAProps) => {
  return (
    <section className="w-full hidden md:flex border-y border-gray-100 flex-col items-center px-10 py-32 space-y-10 bg-orange1 ">
      <h3 className="text-4xl text-blue7 text-center font-montserrat font-medium">
        {props.blok.title}
      </h3>
      <p className="text-center text-2xl lg:max-w-4xl">
        {props.blok.description}
      </p>
      <Anchor href={`/shipments/new`} passHref prefetch={false}>
        <RedirectLink>{props.blok.buttonLabel}</RedirectLink>
      </Anchor>
    </section>
  )
}
