import * as React from "react"
import Image from "next/image"
import { Container } from "../components/container"
import { BlokImage } from "../../storyblok"
import { ImageItem } from "../../storyblok/types"
import { readImage } from "../utils/read-image"

type StatsItem = {
  icon: BlokImage | ImageItem
  title: string
  description: string
}

type StatsProps = {
  blok: {
    items: StatsItem[]
  }
}

export const Stats = (props: StatsProps) => {
  return (
    <Container>
      <div className="w-full m-auto 2xl:px-44 xl:px-36 lg:px-32 md:px-24 sm:px-12 px-4 xl:py-28 lg:py-24 md:py-20 sm:py-16 py-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 bg-gray-50 rounded-md">
        {props.blok.items.map((item, index) => {
          return (
            <StatsItem
              key={index}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          )
        })}
      </div>
    </Container>
  )
}

type StatsItemProps = {
  icon: BlokImage | ImageItem
  title: string
  description: string
}

const StatsItem = (props: StatsItemProps) => {
  return (
    <div className="max-w-full w-80 sm:w-44 mx-auto flex flex-row sm:flex-col">
      <div className="h-20 w-20 mr-4 sm:mr-0 mb-6 p-4 rounded-full flex items-center justify-center bg-white drop-shadow-md">
        <Image
          alt={props.icon.alt}
          src={readImage(props.icon)}
          width={46}
          height={46}
        />
      </div>
      <div>
        <p className="text-primary-500 text-lg font-bold mb-2">{props.title}</p>
        <p className="text-base text-neutral-800 leading-7 font-roboto">
          {props.description}
        </p>
      </div>
    </div>
  )
}
