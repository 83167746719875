"use client"

import * as React from "react"
import { Anchor } from "./anchor"
import Image from "next/image"
import { usePathname } from "next/navigation"

import { RxHamburgerMenu } from "react-icons/rx"
import { FaChevronDown } from "react-icons/fa"
import CSLogo from "../assets/icons/cs-logo-blue.svg"
import classNames from "classnames"

import { Container } from "./container"

import { categories, shippingCategories } from "../constants/categories"

export const Header = () => {
  const [revealPrimaryNavigation, setRevealPrimaryNavigation] = React.useState(
    false,
  )
  const pathname = usePathname()
  const isDogTransportation = pathname?.includes("dog-transportation")
  const [
    revealSecondaryNavigation,
    setRevealSecondaryNavigation,
  ] = React.useState(false)

  const onPrimaryNavigationToggle = () => {
    setRevealPrimaryNavigation((prev) => !prev)
  }

  const onSecondaryNavigationToggle = () => {
    setRevealSecondaryNavigation((prev) => !prev)
  }

  return (
    <div className={classNames(isDogTransportation ? "shadow-lg" : "")}>
      <div className="relative px-4 py-3 border-b border-neutral-200">
        <Container>
          <div className="flex items-center justify-between ">
            <Anchor href="/" prefetch={false}>
              <Image alt="logo" src={CSLogo} width={198} height={39} priority />
            </Anchor>
            <button
              className="block lg:hidden"
              onClick={onPrimaryNavigationToggle}
              aria-label="Toggle Menu"
            >
              <RxHamburgerMenu size={32} className="text-gray-600" />
            </button>
            <ul
              className={classNames(
                "flex flex-col text-lg font-roboto",
                "absolute bg-white z-40 top-full left-0 w-full transition-all duration-500 overflow-hidden ",
                "lg:flex-row lg:relative lg:top-auto lg:left-auto lg:w-auto lg:max-h-none",
                revealPrimaryNavigation ? "max-h-[600px]" : "max-h-0",
              )}
            >
              <li>
                <Anchor href="/shipments/new/" prefetch={false}>
                  <MenuItem outlined highlight>
                    Get Transportation Quotes
                  </MenuItem>
                </Anchor>
              </li>
              <li>
                <Anchor href="/how-it-works" prefetch={false}>
                  <MenuItem>How it Works</MenuItem>
                </Anchor>
              </li>
              <li className="lg:border-l border-neutral-200">
                <Anchor href="/new-drivers" prefetch={false}>
                  <MenuItem outlined>Become a Driver</MenuItem>
                </Anchor>
              </li>
              <li>
                <Anchor href="/login" prefetch={false}>
                  <MenuItem autoWidth>Log in</MenuItem>
                </Anchor>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      {isDogTransportation && (
        <div className="relative bg-blue25 z-30">
          <button
            onClick={onSecondaryNavigationToggle}
            className="w-full flex items-center space-x-2 relative px-4 py-3 bg-blue25 font-roboto text-neutral-700 lg:hidden"
          >
            <span>Browse by Category</span>
            <span
              className={classNames(
                "transform duration-300 transition",
                revealSecondaryNavigation ? "rotate-180" : "rotate-0",
              )}
            >
              <FaChevronDown size={12} className="text-neutral-500" />
            </span>
          </button>
          <ul
            className={classNames(
              "font-roboto px-4 py-3 text-neutral-700 bg-white space-y-2 lg:bg-blue25",
              revealSecondaryNavigation ? "block" : "hidden",
              "lg:flex lg:flex-row lg:items-stretch lg:justify-center lg:space-y-0 lg:py-0 xl:space-x-3 absolute w-full ",
            )}
          >
            {shippingCategories.map((c) => {
              return <CategorySubMenuList item={c} key={c.link} />
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

type MenuItemProps = {
  children: React.ReactNode
  outlined?: boolean
  highlight?: boolean
  autoWidth?: boolean
}

const MenuItem = (props: MenuItemProps) => {
  const { outlined = false, autoWidth = false, highlight = false } = props
  return (
    <span
      className={classNames(
        "flex items-center px-8 text-base py-3 font-medium font-roboto rounded-md border border-transparent",
        outlined === true ? "text-primary-500" : "text-neutral-700",
        autoWidth && "lg:px-0",
        highlight ? "lg:hover:border-primary-500" : "hover:underline",
      )}
    >
      {props.children}
    </span>
  )
}

type CategorySubMenuListProps = {
  item: typeof categories[number]
}

const CategorySubMenuList = (props: CategorySubMenuListProps) => {
  const [show, setShow] = React.useState(false)

  const handleOnShow = () => {
    setShow((prev) => !prev)
  }

  return (
    <li className="relative lg:px-4 py-2 group text-sm">
      {props.item.subcategories.length > 0 ? (
        <>
          <div className="relative pr-6 lg:pr-0">
            <button onClick={handleOnShow}>
              {props.item.label}{" "}
              <span
                className={classNames(
                  "absolute p-2 focus:outlined-none -right-2 top-1/2 transform -translate-y-1/2 transition",
                  show ? "rotate-180" : "rotate-0",
                  "lg:hidden",
                )}
              >
                <FaChevronDown size={12} className="text-neutral-500" />
              </span>
            </button>
          </div>
          <ul
            className={classNames(
              "space-y-2 mt-2 overflow-hidden",
              show ? "block" : "hidden",
              "lg:hidden lg:group-hover:block lg:absolute lg:left-0 lg:top-full lg:bg-white lg:z-30 lg:mt-0 lg:min-w-full lg:w-fit lg:rounded-b-lg lg:shadow-md lg:border-t lg:border-neutral-200",
            )}
          >
            {props.item.subcategories.map((s) => {
              return (
                <CategorySubMenuItem
                  key={s.link}
                  label={s.label}
                  link={s.link}
                />
              )
            })}
          </ul>
        </>
      ) : (
        <div>
          <Anchor
            href={`${props.item.link}`}
            className="hover:underline hover:text-primary-500"
            prefetch={false}
          >
            {props.item.label}
          </Anchor>
        </div>
      )}
    </li>
  )
}

type CategorySubMenuItemProps = {
  label: string
  link: string
}

const CategorySubMenuItem = (props: CategorySubMenuItemProps) => {
  return (
    <li className="pl-6 lg:pl-0 lg:flex lg:w-auto">
      <Anchor
        prefetch={false}
        href={`${props.link}`}
        className="hover:underline lg:flex lg:px-4 lg:py-3 lg:min-w-full lg:w-min lg:hover:bg-blue25 lg:hover:no-underline lg:whitespace-nowrap"
      >
        {props.label}
      </Anchor>
    </li>
  )
}
