import Image from "next/image"
import { Container } from "../components/container"
import { Heading } from "../components/heading"
import { readImage } from "../utils/read-image"
import type { BlokImage } from "../../storyblok"
import type { ImageItem } from "../../storyblok/types"

type OfferItem = {
  icon: ImageItem | BlokImage
  heading: string
  description: string
}

type HomepageOfferProps = {
  blok: {
    title: string
    description: string
    offers: OfferItem[]
  }
}

export const HomepageOffer = (props: HomepageOfferProps) => {
  return (
    <Container>
      <section className="py-8 space-y-8">
        <div className="text-center">
          <Heading variant="h2" component={"h2"}>
            {props.blok.title}
          </Heading>
        </div>
        <div>
          <p className="mb-6 lg:mb-14 font-montserrat font-medium text-2xl text-gray9 text-center max-w-2xl mx-auto">
            {props.blok.description}
          </p>
          <div className="relative flex flex-col lg:flex-row items-center lg:items-start justify-between font-montserrat">
            {props.blok.offers.map((item, index) => {
              return (
                <figure
                  key={index}
                  className="max-w-[330px] flex flex-col items-center text-center text-gray8"
                >
                  <Image
                    className="mb-5"
                    src={readImage(item.icon)}
                    height={180}
                    width={180}
                    alt={item.icon.alt}
                    loading="lazy"
                  />
                  <h3 className="font-semibold text-2xl">{item.heading}</h3>
                  <p className="mt-4 font-medium text-lg">{item.description}</p>
                </figure>
              )
            })}
          </div>
        </div>
      </section>
    </Container>
  )
}
