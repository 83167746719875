import * as React from "react"
import Image from "next/image"
import { Anchor } from "../components/anchor"
import { Link as RedirectLink } from "../components/button"
import TrustpilotIcon from "../components/icons/TrustpilotIcon"
import TrustpilotRatingIcon from "../components/icons/TrustpilotRatingIcon"

import { StarRating } from "../components/star-rating"

import { Container } from "../components/container"
import { Heading } from "../components/heading"
import { BlokImage } from "../../storyblok"
import { ImageItem } from "../../storyblok/types"
import { readImage } from "../utils/read-image"

type Review = {
  reviewer: string
  icon: BlokImage | ImageItem
  title?: string
  category?: string
  description: string
  images?: (BlokImage | ImageItem)[]
}

type HomepageReviewsProps = {
  blok: {
    title: string
    numberOfReviews: number | string
    reviews: Review[]
  }
}

export const HomepageReviews = (props: HomepageReviewsProps) => {
  return (
    <Container>
      <section className="py-10 space-y-8  mb-10 sm:mb-30">
        <div className="relative text-center max-w-[800px] mx-auto">
          <Heading variant="h1" component={"h2"}>
            {props.blok.title}
          </Heading>
        </div>
        <div>
          <aside className="max-w-2xl mb-10 mx-auto px-4 md:flex items-center justify-center">
            <div className="flex items-center justify-center">
              <TrustpilotIcon />
              <TrustpilotRatingIcon className="ml-4" />
            </div>
            <p className="my-2.5 md:ml-4 text-base text-blue5 text-center">
              Based on <b>{props.blok.numberOfReviews}</b> reviews.
            </p>
          </aside>
          <section className="w-full p-0 pb-9 md:pb-14 flex gap-4 md:justify-between overflow-x-auto">
            {props.blok.reviews.map((review, i) => (
              <DriverReviewCard {...review} key={i} />
            ))}
          </section>
          <div className="flex justify-center">
            <Anchor
              href={`/shipments/new/`}
              className="mx-auto rounded-md"
              prefetch={false}
            >
              <RedirectLink>Get Free Quotes Now</RedirectLink>
            </Anchor>
          </div>
        </div>
      </section>
    </Container>
  )
}

interface DriverReviewCardProps {
  reviewer: string
  icon: BlokImage | ImageItem
  title?: string
  category?: string
  description: string
  images?: (BlokImage | ImageItem)[]
}

function DriverReviewCard({
  icon,
  reviewer,
  title,
  category,
  description,
  images,
}: DriverReviewCardProps) {
  return (
    <section className="w-fit border border-black/5 rounded pt-10 pb-8 px-4 lg:px-6 font-roboto text-blue5 bg-white drop-shadow-card">
      <header className="w-max flex">
        <Image
          className="h-[60px] w-[60px] rounded-full"
          src={readImage(icon)}
          alt={icon.alt}
          height={60}
          width={60}
        />
        <div className="ml-4">
          <p className="mb-1 font-semibold text-lg">{reviewer}</p>
          <StarRating value={5} />
        </div>
      </header>
      {title && (
        <p className="mb-6 lg:mt-4 font-montserrat font-semibold text-2xl">
          {title}
        </p>
      )}
      {category && <p className="my-6 lg:my-4 font-bold text-lg">{category}</p>}
      <p className="mt-4 text-lg text-black/80">{description}</p>
      <div className="flex gap-2 mt-4">
        {images?.map((path, i) => (
          <Image
            key={i}
            className="h-24 w-24"
            src={readImage(path)}
            alt={`driver review ${i}`}
            height={160}
            width={160}
          />
        ))}
      </div>
    </section>
  )
}
