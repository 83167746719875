import * as React from "react"
import { Heading } from "../components/heading"

type WhatWeDo = {
  blok: {
    title: string
    content: {
      description: string
    }[]
  }
}

export const WhatWeDo = (props: WhatWeDo) => {
  return (
    <section className="max-w-[980px] p-4 pt-8 mx-auto lg:text-center space-y-10">
      <Heading variant="h1" component={"h2"}>
        {props.blok.title}
      </Heading>
      <div className="font-roboto text-neutral-900 text-2xl space-y-4">
        {props.blok.content.map((item, index) => {
          return <p key={index}>{item.description}</p>
        })}
      </div>
    </section>
  )
}
