import * as React from "react"

import Image from "next/image"
import { Container } from "../components/container"
import { BlokImage } from "../../storyblok"
import { readImage } from "../utils/read-image"

type Company = {
  image: BlokImage
  width: number
  height: number
}

type FeaturedInProps = {
  blok: {
    companies: Company[]
  }
}

export const FeaturedIn = (props: FeaturedInProps) => {
  return (
    <div className="m-auto px-4 md:py-20 sm:py-16 py-12">
      <Container>
        <div className="space-y-10 text-center">
          <p className="text-base font-bold uppercase">As Featured In</p>
          <div className="flex flex-row flex-wrap gap-3 justify-center items-center xl:gap-8">
            {props.blok.companies.map((c, i) => {
              return (
                <div key={i}>
                  <Image
                    alt={c.image.alt}
                    src={readImage(c.image)}
                    width={c.width}
                    height={c.height}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}
