"use client"
import * as React from "react"
import Image from "next/image"
import { Heading } from "../components/heading"
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
} from "react-icons/hi2"
import { readImage } from "../utils/read-image"
import type { BlokImage } from "../../storyblok"

type SupportMember = {
  id: number
  name: string
  position: string
  review: string
  reviewer: string
  image: string | BlokImage
}

export type HomepageSupportProps = {
  blok: {
    title: string
    description: string
    source: string
    team: SupportMember[]
    statistics: string
  }
}

export const HomepageSupport = (props: HomepageSupportProps) => {
  const [supportID, setSupportID] = React.useState(0)
  const { team: supportTeam } = props.blok

  const prevSupport = () => {
    setSupportID((prevID) => {
      return prevID > 0 ? prevID - 1 : 0
    })
  }

  const nextSupport = () => {
    setSupportID((prevID) => {
      return prevID < supportTeam.length - 1 ? prevID + 1 : supportTeam.length
    })
  }

  const currentSupportMember = supportTeam[supportID]
  return (
    <div className="px-4 mx-auto md:max-w-[600px] lg:max-w-[948px] lg:pb-10 lg:py-[100px]">
      <section className="relative py-6 grid lg:grid-cols-2 gap-4 lg:gap-16">
        <article>
          <div className="mb-8">
            <Heading component={"h3"} variant="h2">
              {props.blok.title}
            </Heading>
          </div>
          <p className="mb-6 lg:mb-8 font-roboto text-black/80 text-2xl">
            {props.blok.description}
          </p>
          <p className="mb-6 lg:mb-8 text-black text-lg font-roboto ">
            {props.blok.source}
          </p>
          <p className="py-6 lg:py-8 px-4 lg:px-6 rounded-3xl rounded-tl-none text-black/80 text-lg font-roboto bg-neutral-100">
            {currentSupportMember.review}
            <span className="block mt-4">
              &mdash; {currentSupportMember.reviewer}
            </span>
          </p>
        </article>
        <div>
          <Figure
            name={currentSupportMember.name}
            image={currentSupportMember.image}
            position={currentSupportMember.position}
          />
          <div className="mt-2 flex justify-end lg:justify-start lg:mt-10 gap-8">
            <button
              id="previous-support"
              aria-label="Previous Support"
              className="w-14 p-2 disabled:opacity-30"
              disabled={supportID === 0}
              onClick={() => prevSupport()}
            >
              <HiOutlineArrowLongLeft className="w-10 h-auto" />
            </button>
            <button
              id="next-support"
              aria-label="Next Support"
              className="w-14 p-2 disabled:opacity-30"
              disabled={supportID === supportTeam.length - 1}
              onClick={() => nextSupport()}
            >
              <HiOutlineArrowLongRight className="w-10 h-auto" />
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}

type FigureProps = {
  image: string | BlokImage
  name: string
  position: string
}

function Figure(props: FigureProps) {
  return (
    <figure className="relative">
      <Image
        src={readImage(props.image)}
        alt={props.name}
        width={593}
        height={605}
        loading="lazy"
      />
      <figcaption className="md:absolute left-1/2 bottom-0 md:w-[380px] rounded-md py-2 px-3 bg-white font-roboto text-base lg:text-lg drop-shadow-md -translate-y-4 lg:-translate-x-1/2 lg:translate-y-1/2">
        <h4>{props.name}</h4>
        <p className="m-0 text-lg">{props.position}</p>
      </figcaption>
    </figure>
  )
}
