"use client"

import * as React from "react"
import { storyblokEditable } from "@storyblok/react/rsc"
import Image from "next/image"
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
} from "react-icons/hi2"
import { StarRating } from "../components/star-rating"
import { Heading } from "../components/heading"
import { readImage } from "../utils/read-image"
import { BlokImage } from "../../storyblok"

type Driver = {
  id: number
  shipments: number
  image: string | BlokImage
  alt: string
  testimonyDescription: string
  ratingCompany: string
}

type HomepageMeetDriverProps = {
  blok: {
    title: string
    description: string
    driversCrew: Driver[]
  }
}

export const HomepageMeetDriver = (props: HomepageMeetDriverProps) => {
  const { driversCrew } = props.blok
  const [driver, setDriver] = React.useState(0)

  const nextDriver = () => {
    setDriver((driver + 1) % driversCrew.length)
  }

  const prevDriver = () => {
    setDriver((driver - 1) % driversCrew.length)
  }

  return (
    <div
      className="max-w-5xl mx-auto px-4 lg:pt-[100px] lg:pb-[150px]"
      {...storyblokEditable(props.blok)}
    >
      <section className="relative py-6 flex flex-col gap-8 lg:flex-row">
        <aside className="hidden lg:block max-w-md">
          {driversCrew.map((member, index) => {
            return (
              <figure
                className={
                  "relative " + (driver === index ? "block" : "hidden")
                }
                key={member.id}
              >
                <Image
                  className="rounded-xl"
                  src={readImage(member.image)}
                  alt={member.alt}
                  width={593}
                  height={605}
                  loading="lazy"
                />
                <figcaption className="md:absolute left-1/2 bottom-0 md:w-[380px] rounded-md py-2 px-3 bg-white font-roboto text-base lg:text-lg drop-shadow-md -translate-y-4 lg:-translate-x-1/2 lg:translate-y-1/2">
                  <h4>{member.ratingCompany}</h4>
                  <StarRating value={5} />
                </figcaption>
              </figure>
            )
          })}
        </aside>
        <article className="lg:w-3/5">
          <Heading variant="h2" component={"h3"}>
            {props.blok.title}
          </Heading>
          <aside className="lg:hidden max-w-xs">
            {driversCrew.map((member, index) => {
              return (
                <figure
                  className={
                    "relative " + (driver === index ? "block" : "hidden")
                  }
                  key={member.id}
                >
                  <Image
                    className="rounded-xl"
                    src={readImage(member.image)}
                    alt={member.alt}
                    width={593}
                    height={605}
                    loading="lazy"
                  />
                  <figcaption className="md:absolute left-1/2 bottom-0 md:w-[380px] rounded-md py-2 px-3 bg-white font-roboto text-base lg:text-lg drop-shadow-card -translate-y-4 lg:-translate-x-1/2 lg:translate-y-1/2">
                    <h4>{member.ratingCompany}</h4>
                    <StarRating value={5} />
                  </figcaption>
                </figure>
              )
            })}
          </aside>
          <p className="font-roboto text-black/80 text-2xl mt-6 mb-3">
            {props.blok.description}
          </p>
          {driversCrew.map((member, index) => {
            return (
              <div
                className={"mb-6 " + (driver === index ? "block" : "hidden")}
                key={index}
              >
                <p className="mb-6 px-4 py-6 rounded-3xl rounded-tl-none font-roboto font-medium text-lg text-black/80 bg-gray10">
                  {member.testimonyDescription}
                </p>
                <p className="font-roboto text-2xl">
                  <b>{member.shipments}</b>+ completed shipments so far
                </p>
              </div>
            )
          })}
          <div className="flex justify-end lg:justify-start gap-8">
            <button
              id="previous-driver"
              aria-label="Previous Driver"
              className="w-14 p-2 disabled:opacity-30"
              disabled={driver === 0}
              onClick={() => prevDriver()}
            >
              <HiOutlineArrowLongLeft className="w-10 h-auto" />
            </button>
            <button
              id="next-driver"
              aria-label="Next Driver"
              className="w-14 p-2 disabled:opacity-30"
              disabled={driver === driversCrew.length - 1}
              onClick={nextDriver}
            >
              <HiOutlineArrowLongRight className="w-10 h-auto" />
            </button>
          </div>
        </article>
      </section>
    </div>
  )
}
