import classNames from "classnames"
import React from "react"
import { HiStar } from "react-icons/hi"

interface IStarRating {
  value: number
  className?: string
}

const array = [0, 1, 2, 3, 4]

export const StarRating = ({ value, className }: IStarRating) => {
  return (
    <div className={"flex" + " " + className}>
      {array.map((i) => (
        <HiStar
          key={i}
          className={classNames(
            "h-6 w-6 mr-0.5",
            i < value ? "fill-yellow-400" : "fill-gray-500",
          )}
        />
      ))}
    </div>
  )
}
