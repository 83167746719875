import Image from "next/image"
import { Container } from "../components/container"
import { Heading } from "../components/heading"
import { ImageItem } from "../../storyblok/types"
import { readImage } from "../utils/read-image"

const shipmentsData = [
  {
    imagePath: "/images/homepage/shipment1.webp",
    amount: 1800,
    breed: "Vizla",
    mileage: 1820,
    city: "Northbrook, IL",
  },
  {
    imagePath: "/images/homepage/shipment2.webp",
    amount: 500,
    breed: "Min Pin/Shih Tzu",
    mileage: 1289,
    city: "Apopka, FL",
  },
  {
    imagePath: "/images/homepage/shipment3.webp",
    amount: 797,
    breed: "American Longhair",
    mileage: 742,
    city: "Tularosa, NM",
  },
  {
    imagePath: "/images/homepage/shipment4.webp",
    amount: 725,
    breed: "Motorcycle",
    mileage: 1518,
    city: "San Antonio, TX",
  },
  {
    imagePath: "/images/homepage/shipment5.webp",
    amount: 3700,
    breed: "Motorboat",
    mileage: 731,
    city: "Richmond, KY",
  },
  {
    imagePath: "/images/homepage/shipment6.webp",
    amount: 795,
    breed: "Car",
    mileage: 1078,
    city: "Cheverly, MD",
  },
]

type Shipment = {
  image: ImageItem
  amount: number
  breed: string
  mileage: number
  city: string
}

type HomepageShowcaseProps = {
  blok: {
    title: string
    highlight: string
    heading: string
    description: string
    quote: string
    author: string
    platform: string
    shipments: Shipment[]
  }
}

export const HomepageShowcase = (props: HomepageShowcaseProps) => {
  return (
    <Container>
      <section className="py-6">
        <h3 className="max-w-4xl mx-auto mb-8 lg:mb-16 px-4 font-montserrat font-semibold text-3xl text-center text-gray8">
          {props.blok.title}{" "}
          <span className="relative z-10 bg-yellow-200">
            {props.blok.highlight}
          </span>
        </h3>
        <section className="flex md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 overflow-x-auto">
          {props.blok.shipments.map((shipment, i) => {
            return (
              <figure
                key={`shipmentCard/${i}`}
                className="m-0 relative h-[420px] min-w-[320px] md:min-w-fit w-full rounded-md overflow-hidden"
              >
                <Image
                  className="object-cover object-center"
                  src={readImage(shipment.image)}
                  alt={shipment.image.alt}
                  fill
                  loading="lazy"
                />

                <figcaption className="absolute left-6 bottom-6">
                  <p className="w-fit mb-3 rounded-full py-1.5 px-4 bg-primary-500 text-white text-xl font-montserrat font-medium">
                    ${shipment.amount}
                  </p>
                  <p className="mb-0 font-bold font-roboto text-2xl text-white">
                    {shipment.breed} to {shipment.city}
                  </p>
                  <p className="mb-0 font-medium font-roboto text-white text-lg">
                    {shipment.mileage} mi
                  </p>
                </figcaption>
              </figure>
            )
          })}
        </section>
      </section>
      <section className="py-6 flex flex-col lg:flex-row gap-4 lg:gap-8 mt-20 sm:mt-24">
        <div className="relative mx-auto flex-1 overflow-hidden">
          <Image
            className="scale-110"
            src="/images/homepage/showcase-matches.webp"
            alt="matching with drivers snapshot"
            width={565}
            height={600}
            loading="lazy"
          />
          <Image
            className="absolute bottom-20 right-0 max-w-[220px] md:max-w-[320px] lg:max-w-[280px] xl:max-w-[310px] rounded-lg"
            src="/images/homepage/sarah-and-wayne.webp"
            alt="family photo"
            width={310}
            height={310}
            loading="lazy"
          />
        </div>
        <div className="flex-1 font-montserrat ">
          <Heading component={"h2"} variant="h2">
            {props.blok.heading}
          </Heading>
          <p className="mt-6 mb-6 lg:mb-8 font-medium text-2xl text-gray9">
            {props.blok.description}
          </p>
          <p className="mb-4 py-8 px-6 bg-primary-200 font-medium text-lg text-gray8">
            {props.blok.quote}
            <span className="block mt-6">
              <strong>&ndash; {props.blok.author}</strong> (via{" "}
              {props.blok.platform})
            </span>
          </p>
        </div>
      </section>
    </Container>
  )
}
